<template>
  <div>
    <h1>Página de Pesquisar Consulta</h1>
  </div>
</template>

<script>
export default {

}
</script>
